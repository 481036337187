import axiosInstance from '../../../helpers/axios';

export const registerRequest = (payload) => {
  return axiosInstance.post('auth/register', payload);
};

export const registerGoogleRequest = (payload) => {
  return axiosInstance.post('auth/register-google', payload);
};

export const registerAppleRequest = (payload) => {
  return axiosInstance.post('auth/register-apple-web', payload);
};

export const registerFacebookRequest = (payload) => {
  return axiosInstance.post('auth/register-facebook', payload);
};

export const meRequest = (payload) => {
  return axiosInstance.get(`auth/me?token=${payload}`, {
    headers: {
      Authorization: payload,
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });
};

export const loginRequest = (payload) => {
  return axiosInstance.post('auth/login', payload);
};

export const loginGoogleRequest = (payload) => {
  return axiosInstance.post('auth/login-google', payload);
};

export const loginAppleRequest = (payload) => {
  return axiosInstance.post('auth/login-apple-web', payload);
};

export const loginFacebookRequest = (payload) => {
  return axiosInstance.post('auth/login-facebook', payload);
};

export const updateProfileRequest = (payload) => {
  return axiosInstance.post('updateProfile', payload);
};

export const loginWithFacebookRequest = (payload) => {
  return axiosInstance.get('auth/facebook', payload);
};

export const forgetPassword = (payload) => {
  return axiosInstance.post('auth/forget-password', payload);
};

export const resetPasswordRequest = (payload) => {
  return axiosInstance.post('auth/change-password', payload);
};

export const uploadRequest = (payload) => {
  return axiosInstance.post('auth/upload', payload);
};

export const updatePasswordRequest = (payload) => {
  return axiosInstance.post('auth/updatePassword', payload);
};

export const changeUserWelcomeRequest = () => {
  return axiosInstance.post(`auth/welcome`);
};

export const accountDeleteRequest = () => {
  return axiosInstance.post(`notification/delete-request`);
};
export const deleteProfileImageRequest = () => {
  return axiosInstance.delete('auth/delete-image');
};
